.headerContainer,
.leftContainer {
  background-color: #fff;
  max-width: 480px;
  width: 100%;
}

* {
  font-family: 'Roboto';
}

*,
:after,
:before {
  box-sizing: border-box;
}

.leftContainer {
  min-height: 0%;
  position: relative;
}

.headerContainer {
  position: fixed;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
  height: 0px;
  padding: 0;
  top: 0;
  z-index: 2;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navLogo>img {
  height: 36px;
  width: 36px;
}

.login-btn {
  border: 1px solid #003441;
  color: #003441 !important;
  border-radius: 3px;
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 1px;
  /* height: 30px; */
  padding: 6px 16px;
  margin-right: 10px;
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
}

.login-btn:hover {
  background-color: #003441;
  color: #fff !important;
}

.header_top_message {
  position: absolute;
  top: 0;
  z-index: 9999;
  background-color: rgb(3, 47, 97);
  width: 480px;
  text-align: center;
  padding: 8px;

  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #ffffff !important;

  max-width: 480px;
  width: 100%;
  position: fixed;
}

.mb_space {
  padding-bottom: 60px;
  padding-top: 30px;
}

.other_page_small_headings {
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #222222;
}

.user_reder_code_box {
  display: flex;
  position: relative;
}

.user_reder_code_box .check_btn {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translate(0, -50%);
}

.commaon_input_box {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: #003441;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid rgb(189, 189, 189) !important;
}

.samll_btn {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
  border: none;
  box-shadow: none;
  background-color: #000;
  color: #fff !important;
  border-radius: 5px;
  padding: 6px 16px;
  cursor: pointer;
}

.games_Section_Title {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #fe3347;
}

.Orher_page_main_section {
  margin-top: 20px;
  padding: 15px;
}

.Orher_page_main_section .profile_headings {
  text-align: left;
  margin-bottom: 20px;
}

.Notifications_btns_div {
  display: flex;
  justify-content: space-between;
}

.Orher_page_main_section .nav {
  flex-wrap: nowrap;
  overflow: auto;
}

.Orher_page_main_section .nav-tabs .nav-link {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #060606 !important;
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 20px !important;
  text-transform: capitalize;
}

.Orher_page_main_section .nav-tabs {
  display: flex;
  justify-content: space-between;
  border: none;
}

.Orher_page_main_section .nav-tabs .nav-link.active {
  background-color: #000;
  color: #fff !important;
}

.other_pages_card_main {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  margin-top: 20px;
  /* background-color: #000; */
}

.other_pages_card_main h3 {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #060606 !important;
}

.other_pages_card_main p {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #414141;
  margin-bottom: 0 !important;
}

.dander_text {
  color: rgb(246 81 81) !important;
}

.dark_light_color {
  color: #5a5a5a;
}

.card_rupee {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #414141;
}

.card_date p {
  font-size: 12px !important;
}

.transaction_msg {
  font-size: 12px !important;
}

.transaction_right_side_main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.rightContainer {
  background: url(../../../public/Images/global-bg-rays.svg);
  background-color: #fff;
  background-size: cover;
  overflow: hidden;
  right: 0;
  z-index: 4;
}

.rightContainer {
  bottom: 0;
  left: 480px;
  position: fixed;
  top: 0;
  border-left: 10px solid #e0e0e0;
}

.rcBanner-footer>img {
  height: 25px;
  width: 25px;
  margin-top: 4px;
}

.rcBanner-img-container {
  bottom: 45%;
  position: absolute;
  width: 300px;
}

.rcBanner-img-container>img {
  height: auto;
  width: 300px;
}

.flex-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rcBanner-text {
  bottom: 35%;
  font-family: "Roboto Condensed";
  font-size: 2em;
  font-weight: 400;
  position: absolute;
  text-transform: uppercase;
}

.rcBanner-text-bold {
  font-weight: 900;
}

.main-area {
  background-color: transparent;
  overflow: hidden;
  width: 100%;
}

.games-section {
  background: #fff;
  border-radius: 0;
  position: relative;
  width: 100%;
}

.p-3 {
  padding: 1rem !important;
}

.games-section-headline {
  color: #959595;
  font-size: .75em;
  font-weight: 400;
  line-height: 18px;
}

.games-window {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gameCard-container {
  /* margin-top: 20px; */
  justify-content: center;
  position: relative;
  width: 46.8%;
}

.blink {
  animation: animate 1s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}



.gameCard {
  background-color: #fff;
  border-radius: 5px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}

.gameCard-image {
  border-radius: 5px;
  height: 68.33%;
  width: 100%;
}

.gameCard-image img {
  border-radius: 5px;
}

.gameCard-icon,
.gameCard-title {
  display: flex;
  justify-content: center;
}

.gameCard-title {
  border-radius: 0 0 5px 5px;
  border: 1px solid #e0e0e0;
  border-top: none;
  color: #2c2c2c;
  font-weight: 700;
  padding: 15px 20px 10px;
}

.games-section-title {
  color: #2c2c2c;
  font-size: 1em;
  font-weight: 100;
}

.rcBanner-footer {
  bottom: 40px;
  color: #2c2c2c;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  text-align: center;
}

.profile_headings {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #0a0808;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

/* =========Add cash page=============== */

.add_amount_main_box_inner .label {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #000000 !important;
}

.enter_amount_input_box {
  position: relative;
}

.enter_amount_input_box span {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #414141 !important;
}

.add_amount_main_box_inner input {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #414141 !important;
  width: 100%;
  padding: 8px 0 8px 35px;
  border: 1px solid #8a8a8a;
  border-radius: 5px;
}

.add_amount_main_box_inner input:focus {
  border: 1px solid #242424;
}

.add_amount_min_max {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.add_amount_min_max p {
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #f86262 !important;
}

.add_amount_buttons_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.download_btn_bottom {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
}

.add_amount_buttons_main button {
  border: none;
  background-color: #de080a;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.add_amount_buttons_main button:focus {
  background-color: #fe3347;
}

.add_cash_nest_btn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_cash_nest_btn button {
  background-color: #000;
  width: 80%;
  margin: auto !important;
  border: none;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  letter-spacing: 0.5px;
}

.add_cash_nest_btn button .icons {
  color: #fff !important;
  font-size: 24px;
  margin-left: 10px;
}

.deposit_service {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  letter-spacing: 0.5px;
}

.add_cash_small_haedings {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}

.samll_btn {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
  border: none;
  box-shadow: none;
  background-color: #145c6e;
  color: #fff !important;
  border-radius: 5px;
  padding: 6px 16px;
  cursor: pointer;
}

.samll_btn .icons {
  margin-right: 5px;
  font-size: 22px;
}

.normal_btn_text {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}

.samll_card_btn_type {
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
}

.support_samll_card_btn_type {
  background-color: #314c65;
  padding: 0.2rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.support_samll_card_btn_type a,
.support_samll_card_btn_type span {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  letter-spacing: 0.5px;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.select_box {
  margin-top: 30px !important;
  width: 100%;
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
}

.select_box option {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}

.company_address {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  letter-spacing: 0.5px;
  color: #000 !important;
  margin-top: 30px !important;
}

.support_img_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.support_img_box img {
  max-width: 50%;
}

.footer {
  /* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important; */
  border-radius: 0 !important;
  position: relative !important;
  width: 100% !important;

  padding: 1% !important;
}

.footer-divider {

  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer-links>a {
  color: #959595 !important;
  display: flex !important;
  font-size: 1.1em !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
}

.footer-text-bold {
  color: #959595;
  font-size: 1em;
  font-weight: 700;
}

.footer-text {
  color: #959595;
  font-size: .9em;
  font-weight: 400;
}

a:hover {
  color: #0056b3 !important;
  text-decoration: underline;
}

a {
  color: #007bff !important;
  text-decoration: none;
  background-color: transparent;
}



.gameCard-container .goverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.collapseCard-container {
  padding: 30px 20px 20px;
  background-color: #fafafa;
}

.collapseCard {
  position: relative;
  width: 100%;
}

.collapseCard-body {
  padding-left: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.collapseCard-header {
  background-color: #fafafa;
  padding-left: 3px;
  padding-right: 3px;
  top: -13px;
  align-items: center;
  position: absolute;
  display: flex;
  justify-content: center;
}

.collapseCard-title {
  color: #676767;
  font-size: .7em;
  font-weight: 700;
  text-transform: uppercase;
}

/* ===============refer page=============== */
.refer_page_main_section {
  padding: 20px;
  padding-top: 80px;
}

.refer_page_top_main_box_outer {
  border-radius: 8px;
  border: 1px solid #c8c8c8;
}
.battle_card_main {
  background-color: #f6f8fa;
  padding: 0.6rem 0rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
.card_bottom_border {
  border: 1px solid #00209d !important;
}
.battle_card_main .headings {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #060606 !important;
  padding-left: 0.6rem !important;
}
.card_icons {
  font-size: 26px;
  color: #fff;
}

.refer_page_top_main_box h2 {
  /* background-color: rgba(200, 236, 217, 0.27); */
  padding: 10px;
  text-align: center;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: var(--FF) !important;
  color: #fff !important;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  background-color: #2b6b37;
  padding: 0.6rem;
  /* border: 1px solid #c8c8c8; */
}

.refer_page_top_main_box_inner {
  display: flex;
  padding: 10px;
}

.refer_page_top_main_box_inner_single {
  width: 50%;
  text-align: center;
  margin: auto;
}

.refer_page_top_main_box_inner_single h3 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.bo_le {
  border-right: 1px solid rgb(0 0 0 / 50%);
}

.Refer_code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.Refer_code h3 {
  width: 80%;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid rgb(0 0 0 / 50%);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
  letter-spacing: 1px;
  text-transform: capitalize;
  padding-left: 5px;
}

.Refer_code button {
  width: 20%;
  height: 40px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: #314c65;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: -7px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #fff !important;
}

.or_div h2 {
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 17px;
  letter-spacing: 1px;
}

.whatsapp_share_btn {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}

.whatsapp_share_btn a {
  background-color: #198754;
  color: #fff !important;
  padding: 10px;
  border-radius: 8px;
  width: 90%;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  max-width: 50px;
}

.whatsapp_share_btn a:hover {
  background-color: #000000;
  color: #fff !important;
}

.refer_rules p {
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}

.profile_headings,
.profile_details h2,
.profile_wallet,
.Profile_mytext__v9ly5,
.commaon_input_box {
  font-size: 14px;
}

.samll_btn .icons {
  margin-right: 5px;
  font-size: 22px;
}

.normal_btn_text {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}

.samll_card_btn_type {
  background-color: #f6f8fa;
  padding: 0.6rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
}

.support_samll_card_btn_type {
  background-color: #1b0cef;
  padding: 0.2rem 1rem !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 10px !important;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.support_samll_card_btn_type a,
.support_samll_card_btn_type span {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  letter-spacing: 0.5px;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.company_address {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  letter-spacing: 0.5px;
  color: #000 !important;
  margin-top: 30px !important;
}

.support_img_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.support_img_box img {
  max-width: 50%;
}

.profile_info_small_box_main_row {
  padding: 24px 15px;
  background-color: #fff;
}

.profile_info_small_box_main {
  background-color: #c8c8c8;
  ;
  padding: 0.6rem;
  border: 1px solid #c8c8c8;
  border-radius: 0.375rem;
  min-height: 140px;
  /* margin-bottom: 20px; */
}

.profile_info_small_box_main h3 {
  font-size: 16px;
  font-weight: 600;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.profile_info_small_box_main h4 {
  font-size: 18px;
  font-weight: 600;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0px;
}

.profile_info_small_box_inner {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 10px;
  /* background-color: #5a9ad5; */
  padding: 13px
}

/* .bg_1 {
  background-color: rgb(244, 195, 195);
}
.bg_2 {
  background-color: rgb(221 195 244);
}
.bg_3 {
  background-color: rgb(186, 250, 173);
} */
.profile_info_small_box_inner img {
  max-width: 30px;
}

.profile_div_main_box {
  background-color: #2b6b37;
  margin-top: 70px;
  padding: 15px;
  border: 1px solid rgba(30, 41, 59, 0.1);
  display: flex;
  align-items: center;
  margin: 8px;
  border-radius: 10px;
}

.profile_wallet {
  background-color: #72b6c7 !important;
  color: #fff !important;
  font-size: 17px;
  padding: 6px 16px;
  font-family: 'Poppins', sans-serif;
  box-shadow: none !important;
  border: none !important;
  outline: none;
  border-radius: 5px !important;
}

.profile_wallet:hover {
  color: #fff !important;
}

.new_kyc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.complete_kyc_btn {
  background-color: #209ed8 !important;
  color: #fff !important;
  font-size: 18px;
  padding: 6px 16px;
  font-family: 'Poppins', sans-serif;
  width: fit-content;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-top: 10px;
}

.complete_kyc_btn:hover {
  color: #000 !important;
}

.Profile_mytext__v9ly5 {
  color: #fff !important;
}

.complete_kyc_btn img {
  max-width: 30px;
  margin-right: 10px;
}

.profile_div_main_inner {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.profile_div_main_inner .profie_img {
  border-radius: 50%;
  max-width: 80px;
  height: 80px;
  width: 80px;
  margin-right: 20px;
}

.profile_details {
  margin-left: 20px;
}

.profile_heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.profile_details h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* line-height: 30px; */
  color: #fff;
  margin-bottom: 4px;
  font-family: 'Mulish', sans-serif !important;
}

.profile_details h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 17px; */
  color: #d5d5d5;
  /* margin-top: 8px; */
  margin-bottom: 4px;
  font-family: 'Mulish', sans-serif !important;
}

.Complete_Profile_box {
  /* background-color: #1B4242; */
  padding: 15px;
  border: 1px solid rgba(30, 41, 59, 0.1);
  margin: 15px;
  border-radius: 10px;
}

.border_top {
  border: 1px solid #e0e0e0;
}

.profile_headings {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #231d1d;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.commaon_input_box {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: #003441;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid rgb(189, 189, 189) !important;
}

.commaon_label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #003441;
}

.profile_name_edit {
  margin-left: 30px;
  cursor: pointer;
}

.profile_edit_input {
  padding: 5px;
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3d3d3d;

  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.user_reder_code_box {
  display: flex;
  position: relative;
}

.user_reder_code_box .check_btn {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translate(0, -50%);
}

.logout_div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.logout_div a {
  background-color: #000;
  margin: 0 15px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #fff !important;
  padding: 12px 24px;
  width: fit-content;
}

.logout_div button {
  background-color: #fe3347;
  margin: 0 15px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #fff !important;
  padding: 12px 24px;
  width: fit-content;
  border: none;
}

.logout_div a:hover {
  color: #fff !important;
}

.profile_history_btns_div {
  border: 1px solid #e0e0e0;
}

.profile_history_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile_history_btns a {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #060606 !important;
  background-color: #f6f8fa;
  padding: 0.6rem 2rem;
  border: 1px solid #c8c8c8;
  border-radius: 20px;

  text-align: center;
}

.card_icons {
  font-size: 26px;
  color: #fff;
}