#hambergar:hover{
    background-color: white !important;
}
.w3-button{
    /* background-color:blueviolet; */
    color:white;
    /* position:fixed */
    margin: auto;
    right: 0;
    top:20px;
    /* right:/20px; */
    padding:0.25rem;
    transition:transform 0.3s ease-in-out;
    /* background: linear-gradient(to right, #ff416c, #ff4b2b); */
}
.w3-button.active{
    transform: translateX(-100px);
}

.w3-sidebar{
    
    width:70%;
    max-width:300px;
    height: 100%;
    background-color: #fff;
    color: black !important;
    position:fixed;
    top:0;
    left:-500px;
    height:100vh;
    transition:all 0.5s ;
    z-index: 99999999 !important;
    
}
.w3-container{
    padding:0px;
    top: 0 !important;
    margin: auto;
    background: #fff !important;
    
}
.w3-teal{
    background: white !important;

}
.w3-bar-block .w3-bar-item {
    background-color: #fff;
    
    /* padding: 20px; */
    height: 74px;
    position: relative;
    width: 100%;
    align-items: center;
    display: flex;
    color: #000 !important;
}

/*.w3-bar-block .w3-bar-item:hover{*/
/*    background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118));*/
/*    color: black !important;*/
/*}*/


.arrow{
    position: absolute;
    right:25px;
}
.arrow>img {
    height: 11px;
    width: 6px;
    max-width: 100%;
}

.icon{
    overflow: hidden;
    position: relative;
}
.icon>img{
    height:28px;
    width: 28px;
    /* max-width: 100%; */
}
#sidebarOverlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    z-index: 9999999;
    display: none;
    transition: all .5s ease-in-out 0s;
}
.sidebar_header {
  background-color: #000 !important;
  padding: 20px;
}

.success_msg_div {
  background-color: #d9f3cd;
  padding: 1rem;
  margin-bottom: 20px;
  border: 1px solid #046409;
  border-radius: 0.375rem;
}

.alrt_msg_div {
  background-color: #fbd0d5;
  padding: 1rem;
  margin-bottom: 20px;
  border: 1px solid #faa3ad;
  border-radius: 0.375rem;
}
.alrt_msg_div h5 {
  font-size: 0.7rem;
  line-height: 16px;
  text-align: left;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}
.alrt_msg_div span {
  font-size: 1.2rem;
  line-height: 16px;
  text-align: left;
  color: #003441;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}

.alrt_msg_div a {
  font-size: 1rem !important;
  line-height: 16px;
  text-align: left;
  color: #f5f5f5 !important;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
}
.sidebar_header h2 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.bottom_menus_main{
    bottom: 0 !important;
    margin: auto !important;
    max-width: 480px;
    position: fixed !important;
    width: 100%;
    z-index:3;
}
.main_bottom_menus {
    max-width: 100%;
    margin: auto !important;
    background-color: rgb(3, 47, 97);
    border-radius: 0px;
    padding: 15px 10px;
  }
  .bottom_link_main {
    display: flex;
    justify-content: space-between;
  }
  .bottom_link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
  .bottom_link span {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    display: none;
    transition: 0s all ease-in-out;
  }
  .active_link {
    transition: 0s all ease-in-out;
  }
  .active_link span,
  .active_link .sidebar_link_icon {
    color: rgb(255, 255, 255) !important;
    transition: 0s all ease-in-out;
  }
  .active_link span {
    margin-left: 8px;
  }
  .active_link {
    background-color: rgb(198, 198, 198);
    background-color: rgb(14 16 17);
    padding: 5px 8px;
    border-radius: 20px;
    transition: 0s all ease-in-out;
  }
  .active_link span {
    display: block;
    color: #fff !important;
    transition: 0s all ease-in-out;
  }
  .active_link span .sidebar_link_icon {
    color: #fff !important;
  }
  .sidebar_link_icon {
    color: #fff !important;
    font-size: 22px;
  }
  .active_link .sidebar_link_icon {
    font-size: 20px;
  }